import "./ContentSection3.scss";
import { ImageViewer } from "react-image-viewer-dv";

import pyreIcon from "../../img/pyreIcon-152x152.png";
import vennDiagram from "../../img/vennDiagram.png";

const ContentSection3 = () => {
    return (
        <>
            <div className="section3HeaderWrapper">
                <img
                    src={pyreIcon}
                    alt="pyreswap icon"
                    className="section3HeaderIcon"
                />
                <div className="section3Header">
                    The Greater PyreSwap Ecosystem is the first Decentralized
                    Credit Union!
                </div>
            </div>

            <div className="section3Wrapper">
                <div className="section3TextWrapper">
                    <div className="section3Text">
                        PyreSwap’s ecosystem of integrated applications, known
                        as the Greater PyreSwap Ecosystem (GPSE), has the
                        highest amount of complementary currencies in all of
                        web3!
                    </div>
                    <div className="section3Text">
                        PyreSwap is an evolutionary milestone in DeFi, and
                        introduces{" "}
                        <span className="yellowText">yield scalping</span> and{" "}
                        <span className="orangeText">active staking</span> to
                        the gestalt of DeFi investment strategies!
                    </div>
                    <div className="section3Text">
                        The GPSE’s tokenomics work in both bullish and bearish
                        market conditions!
                    </div>
                    <div className="section3Text">
                        <span className="redText">"Red Candlesticks"</span>{" "}
                        cannot kill PyreSwap, or any GPSE tokens! (You are
                        welcome to try!)
                    </div>
                </div>

                <div className="section3ImgWrapper">
                    <ImageViewer>
                        <img
                            src={vennDiagram}
                            alt="venn diagram"
                            className="section3Img"
                        />
                    </ImageViewer>
                </div>
            </div>
        </>
    );
};

export default ContentSection3;
