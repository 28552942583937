import "./ContentSection1.scss";
import { ImageViewer } from "react-image-viewer-dv";

import pyreIcon from "../../img/pyreIcon-152x152.png";
import colorDiagram from "../../img/colorDiagram.png";

const ContentSection1 = () => {
    return (
        <>
            <div className="section1HeaderWrapper">
                <img
                    src={pyreIcon}
                    alt="pyreswap icon"
                    className="section1HeaderIcon"
                />
                <div className="section1Header">
                    PyreSwap is <span className="orangeText">nothing</span> like
                    your last DEX!
                </div>
            </div>

            <div className="section1Wrapper">
                <div className="section1TextWrapper">
                    <div className="section1Text">
                        PyreSwap is an AMM and a DEX currently available on the
                        following networks:
                    </div>
                    <ul className="section1List">
                        <li>Fantom Opera</li>
                        <li>Avalanche C-Chain</li>
                        <li>BNB Smart Chain</li>
                    </ul>
                    <div className="section1Text mb35">
                        Additionally, PyreSwap is an elaborate GameFi project
                        with its own complete economy, being built across six
                        phases in its roadmap.
                    </div>
                    <div className="section1Text">
                        PyreSwap is produced by the Seigniorage Circus, and has
                        been in development since 2022.
                    </div>
                </div>

                <div className="section1ImgWrapper">
                    <ImageViewer>
                        <img
                            src={colorDiagram}
                            alt="overview GPSE"
                            className="section1Img"
                        />
                    </ImageViewer>
                </div>
            </div>
        </>
    );
};

export default ContentSection1;
