import "./StartInfo.scss";

import { useEffect, useState } from "react";
import axios from "axios";

import titleImg from "../../img/title.png";

import Skeleton from "@mui/material/Skeleton";

const StartInfo = () => {
    const [tvl, setTvl] = useState();
    const [loadingTvl, setLoadingTvl] = useState(true);
    const [errorTvl, setErrorEpoch] = useState(false);

    const errorMsg = "Error!";

    useEffect(() => {
        setErrorEpoch(false);
        setLoadingTvl(true);
        axios
            .get("https://api.llama.fi/tvl/pyreswap")
            .then((res) => {
                setTvl(Math.round(res.data));
            })
            .catch((e) => {
                setErrorEpoch(true);
                console.log(e);
            })
            .finally(() => {
                setLoadingTvl(false);
            });
    }, []);

    const skeleton = (width, height) => {
        return (
            <Skeleton className="mainSkeleton" width={width} height={height} />
        );
    };

    return (
        <>
            <img src={titleImg} alt="title" className="startInfoTitleImg" />

            <div className="startInfoTvlWrapper">
                <div className="startInfoTvl">
                    {!errorTvl && tvl
                        ? `TVL: $${tvl.toLocaleString()}`
                        : loadingTvl
                        ? skeleton(150, 36)
                        : `TVL: ${errorMsg}`}
                </div>
            </div>

            <div className="startInfoTvb">TVB: $???</div>
        </>
    );
};

export default StartInfo;
